import { HTMLAttributes, PropsWithChildren } from 'react'
import clsx from 'clsx'
import classnames from './Typography.module.css'

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
type TextTag = 'p' | 'span' | 'label'
type HeadingSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
type TextSize = 'xs' | 'sm' | 'md' | 'lg'
type LineHeight = 'none' | 'tight' | 'normal' | 'relaxed' | 'loose'

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  as?: HeadingTag
  size?: HeadingSize
  lh?: LineHeight
  balance?: boolean
  center?: boolean
}

interface TextProps
  extends HTMLAttributes<
    HTMLParagraphElement & HTMLSpanElement & HTMLLabelElement
  > {
  as?: TextTag
  size?: TextSize
  lh?: LineHeight
  balance?: boolean
  center?: boolean
}

const headingSizeStyles: Record<HeadingSize, string> = {
  xs: classnames.heading_xs,
  sm: classnames.heading_sm,
  md: classnames.heading_md,
  lg: classnames.heading_lg,
  xl: classnames.heading_xl,
  xxl: classnames.heading_xxl,
}

const textSizeStyles: Record<TextSize, string> = {
  xs: classnames.text_xs,
  sm: classnames.text_sm,
  md: classnames.text_md,
  lg: classnames.text_lg,
}

const lineHeightStyles: Record<LineHeight, string> = {
  none: classnames.line_height_none,
  tight: classnames.line_height_tight,
  normal: classnames.line_height_normal,
  relaxed: classnames.line_height_relaxed,
  loose: classnames.line_height_loose,
}

const textWrapBalance = classnames.text_balance

const textAlignCenter = classnames.text_center

const Heading = ({
  as: Tag = 'h1',
  size = 'md',
  lh = 'normal',
  balance,
  center,
  children,
  className,
  ...props
}: PropsWithChildren<HeadingProps>) => {
  return (
    <Tag
      className={clsx(
        headingSizeStyles[size],
        lineHeightStyles[lh],
        balance && textWrapBalance,
        center && textAlignCenter,
        className,
      )}
      {...props}
    >
      {children}
    </Tag>
  )
}

const Text = ({
  as: Tag = 'p',
  size = 'md',
  lh = 'normal',
  balance,
  center,
  children,
  className,
  ...props
}: PropsWithChildren<TextProps>) => {
  return (
    <Tag
      className={clsx(
        textSizeStyles[size],
        lineHeightStyles[lh],
        balance && textWrapBalance,
        center && textAlignCenter,
        className,
      )}
      {...props}
    >
      {children}
    </Tag>
  )
}

export { Heading, Text }
