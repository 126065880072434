import { PropsWithChildren, useEffect, useState } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import AliceCarousel, { Props } from 'react-alice-carousel'
import clsx from 'clsx'
import classes from './Carousel.module.css'

export function Carousel({
  children,
  className,
  ...props
}: PropsWithChildren<
  Props & {
    className?: string
  }
>) {
  return (
    <div className={clsx(classes.carouselWrapper, className)}>
      <AliceCarousel {...props}>{children}</AliceCarousel>
    </div>
  )
}

export function useCarousel<T>(slides: T[]) {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const isTablet = useMediaQuery('(max-width: 992px)')
  const [slidesToRender, setSlidesToRender] = useState<T[]>([])
  const [disabledDots, setDisabledDots] = useState(true)

  useEffect(() => {
    setSlidesToRender(slides)

    const disableDots = slidesCountHandler(slides.length, isMobile, isTablet)

    setDisabledDots(disableDots)
  }, [isMobile, isTablet])

  return {
    slidesToRender,
    carouselSettings: {
      infinite: true,
      disableButtonsControls: true,
      disableDotsControls: disabledDots,
      touchTracking: !disabledDots,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 2,
        },
        992: {
          items: 3,
        },
      },
    },
  }
}

function slidesCountHandler(
  slidesLength: number,
  isMobile: boolean,
  isTablet: boolean,
) {
  if (slidesLength <= 1) {
    return true
  }

  if (slidesLength <= 2 && isTablet) {
    return true
  }

  if (slidesLength <= 3 && !isTablet && !isMobile) {
    return true
  }

  return false
}
