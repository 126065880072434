import { ComponentType } from 'react'
import {
  AdeptiaLogo,
  AtomicworkLogo,
  AxiamaticLogo,
  BrameLogo,
  ConstantContactLogo,
  GlobalAppTestingLogo,
  JiminnyLogo,
  LleverageLogo,
  QuickbaseLogo,
  SequenceLogo,
  SpecterLogo,
  SpekitLogo,
  SpendbaseLogo,
  StoryblokLogo,
  TactiqLogo,
} from './CustomerLogosIcons'

export type CustomerLogosDataType = {
  name: string
  url: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Logo: ComponentType<any>
}

export const customerLogosData: CustomerLogosDataType[] = [
  {
    name: 'ConstantContact',
    url: 'https://www.constantcontact.com/',
    Logo: ConstantContactLogo,
  },
  {
    name: 'Quickbase',
    url: 'https://www.quickbase.com/',
    Logo: QuickbaseLogo,
  },
  {
    name: 'Adeptia',
    url: 'https://www.adeptia.com/',
    Logo: AdeptiaLogo,
  },
  {
    name: 'Jiminny',
    url: 'https://jiminny.com/',
    Logo: JiminnyLogo,
  },
  {
    name: 'Storyblok',
    url: 'https://www.storyblok.com/',
    Logo: StoryblokLogo,
  },
  {
    name: 'Spekit',
    url: 'https://www.spekit.com/',
    Logo: SpekitLogo,
  },
  {
    name: 'Tactiq',
    url: 'https://tactiq.io/',
    Logo: TactiqLogo,
  },
  {
    name: 'Brame',
    url: 'https://brame.io/',
    Logo: BrameLogo,
  },
  {
    name: 'Axiamatic',
    url: 'https://www.axiamatic.com/',
    Logo: AxiamaticLogo,
  },
  {
    name: 'Atomicwork',
    url: 'https://www.atomicwork.com/',
    Logo: AtomicworkLogo,
  },
  {
    name: 'Global App Testing',
    url: 'https://www.globalapptesting.com/',
    Logo: GlobalAppTestingLogo,
  },
  {
    name: 'Spendbase',
    url: 'https://www.spendbase.co/',
    Logo: SpendbaseLogo,
  },
  {
    name: 'Specter',
    url: 'https://www.tryspecter.com/',
    Logo: SpecterLogo,
  },
  {
    name: 'Sequence',
    url: 'https://www.sequencehq.com/',
    Logo: SequenceLogo,
  },
  {
    name: 'Lleverage',
    url: 'https://www.lleverage.ai/',
    Logo: LleverageLogo,
  },
]
